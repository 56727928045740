.container {
  height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #fafafa;

    .inner-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #e4dcdc;
        border-radius: 10px;
        text-align: center;
        width: 100%;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 480px) {
            max-width: 86%;
            height: 400px
        }

        .unsubscribe-button-container {
            display: flex;
            gap: 16px;
            margin-top: 14px;
            justify-content: center;
            margin-bottom: 20px;

            @media screen and (max-width: 480px) {
                flex-direction: column;
            }

            .unsubscribe-button {
                background-color: #265d54;
                border-color: #265d54;
                font-weight: 500;
                color: #fff;
                font-size: 16px;
                padding: 20px 20px;
                border-radius: 5px;
                transition: all 0.3s ease;
                
                &:hover {
                    background-color: #1b4039 !important;
                    border-color: #1b4039 !important;
                }
            }
        }
        p {
            margin-top: 0px;
            letter-spacing: 0.1px;
            font-size: 14px;
            line-height: 1.4;
            font-family: sans-serif;
        }
    }

}
