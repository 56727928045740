.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/*Login and registration*/

.col {
	padding-top: 30px;
}
.button1 {
	background-color: #08594A;
	border-color: #08594A;
	align-items: center;
	font-size: 1rem;
	border-radius: 0.5rem;
}
.eye {
	float: right;
	margin-right: 35px;
	margin-top: -42px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	font-size: xx-large;
	border: none;
	background: center;
	color: darkgray;
}
.check {
	text-align: left;
	font-weight: 480;
	font-size: large;
}
.password {
	text-align: left;
	font-weight: 480;
	font-size: large;
}
.username {
	text-align: left;
	font-weight: 480;
	font-size: large;
}
#flexCheckDefault {
	font-size: large;
}
.loginform {
	width: 430px;
	font-size: 1.25rem;
	padding: 0.5rem 1rem;
	min-height: calc(1.5em + 1rem + 2px);
}
.forminput {
	border-radius: 0.5rem;
	width: 430px;
	min-height: calc(1.5em + 1rem + 2px);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
