body {
  margin: 0;
  font-family: "Lato Regular", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased !important;
}


.grecaptcha-badge { visibility: hidden; }

@font-face {
  font-family: "Lato Regular"; 
  src: url("./assets/custom-fonts/Lato-Regular.ttf") format("truetype");
}
